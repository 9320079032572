import React from 'react';

const Footer = () => {
  return (
    <footer className="fixed bottom-0 w-full bg-white text-black text-center text-xs py-4">
      Copyright © 2024 Jade. All rights reserved
    </footer>
  );
};

export default Footer;