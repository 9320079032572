// WhatsAppButton.jsx
import React from 'react';

const WhatsAppButton = () => {
	return (
		<a
			href='https://wa.me/+541161191960?text=Hola me comunico para consultar por una reserva. Nombre: Fecha de La reserva: Cantidad de personas: .' // Reemplaza con tu número de WhatsApp
			className='fixed bottom-20 right-5 bg-green-500 text-white rounded-full p-4 shadow-lg hover:bg-green-600'
			target='_blank'
			rel='noopener noreferrer'
		>
			<i className='fab fa-whatsapp text-3xl h-8 w-8'></i>
		</a>
	);
};

export default WhatsAppButton;
