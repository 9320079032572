import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CounterInput } from '../components/CounterInput';
import BookingService from '../services/BookingService';


const Form = () => {
  const [checkIn, setCheckIn] = useState(null);
  const [checkOut, setCheckOut] = useState(null);
  const [exactDate, setExactDate] = useState(false);
  const [openPopupOk, setOpenPopupOk] = useState(false);
  const [openPopupError, setOpenPopupError] = useState(false);

  const handleChangeCheckIn = (value) => {
    if (!checkOut || checkOut?.isAfter(value)) {
      setCheckIn(value);
    }
  };

  const handleChangeCheckOut = (value) => {
    if (!checkIn || checkIn?.isBefore(value)) {
      setCheckOut(value);
    }
  };

  const toggleExactDate = () => {
    setExactDate((prevValue) => !prevValue);
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    handleOffPopup();
    const formData = new FormData(event.target);

    const fullName = formData.get('full_name');
    const guestsAmount = Number(formData.get('amount_travelers'));
    const countryCode = formData.get('country_code');
    const phone = `${countryCode.startsWith('+') ? '' : '+'
      }${countryCode}${formData.get('phone')}`;
    const dateCheckIn = checkIn?.toDate().toISOString();
    const dateCheckOut = checkOut?.toDate().toISOString();

    const formObject = {
      fullName,
      guestsAmount,
      phone,
      isDateExact: exactDate,
      dateCheckIn,
      dateCheckOut,
      temporario: "Sin Asignar"
    };

    const response = await BookingService.sendForm(formObject);
    if(response == "Reserva exitosa" ){
      setOpenPopupOk(true);
    }else{
      setOpenPopupError(true);
    }
  };

  const handleOffPopup = () => {
    setOpenPopupOk(false);
    setOpenPopupError(false);
  }

  return (
    <div className='w-full max-w-2xl mx-auto text-center text-4xl text-black py-4 sm:px-6 overflow-auto'>
      <div className='container sm:max-w-screen-sm mx-auto'>
        <div>
          <h2 className='text-3xl font-bold mb-4'>Contactanos</h2>
          <form
            onSubmit={handleSubmit}
            className='bg-white rounded shadow-lg py-12 px-8 sm:px-16 mb-6 grid h-auto gap-6 text-sm grid-cols-1'
          >
            <div className='h-fit row-start-1 text-start'>
              <label className='text-start w-full' htmlFor='full_name'>
                Nombre Completo
                <input
                  type='text'
                  name='full_name'
                  id='full_name'
                  className='h-10 border mt-1 rounded px-4 w-full bg-gray-50  hover:border-gray-50'
                  placeholder='John Doe'
                  defaultValue=''
                />
              </label>
            </div>

            <div className='h-fit row-start-2 flex flex-col items-center'>
              <label className='text-start' htmlFor='amount_travelers'>
                Cantidad de huespedes
                <CounterInput
                  min={1}
                  max={10}
                  name='amount_travelers'
                  id='amount_travelers'
                />
              </label>
            </div>

            <div className='h-fit row-start-3 flex items-center gap-4'>
              <label className='text-start w-full'>
                Fecha de Ingreso
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    value={checkIn}
                    slotProps={{
                      textField: {
                        size: 'small',
                        className: 'bg-gray-50 hover:border-gray-50 p-none',
                      },
                    }}
                    onChange={(value) => {
                      handleChangeCheckIn(value);
                    }}
                  />
                </LocalizationProvider>
              </label>
              <label className='text-start w-full'>
                Fecha de Egreso
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    format='DD/MM/YYYY'
                    value={checkOut}
                    slotProps={{
                      textField: {
                        size: 'small',
                        className: 'bg-gray-50 hover:border-gray-50 p-none',
                      },
                    }}
                    onChange={(value) => {
                      handleChangeCheckOut(value);
                    }}
                  />
                </LocalizationProvider>
              </label>
            </div>

            <div className='h-fit row-start-4 flex flex-col items-center'>
              <div>
                <label
                  htmlFor='exact_date'
                  className='text-start flex items-center gap-1 cursor-pointer'
                >
                  <input
                    type='checkbox'
                    id='exact_date'
                    className='form-checkbox cursor-pointer'
                    checked={exactDate}
                    onChange={toggleExactDate}
                  />
                  Esta es mi fecha de viaje exacta
                </label>
                <label
                  htmlFor='exact_date'
                  className='text-start flex items-center gap-1 cursor-pointer'
                >
                  <input
                    type='checkbox'
                    id='exact_date'
                    className='form-checkbox cursor-pointer'
                    checked={!exactDate}
                    onChange={toggleExactDate}
                  />
                  Esta es mi fecha de viaje aproximada
                </label>
              </div>
            </div>

            <div className='h-fit row-start-5 flex items-center gap-4'>
              <label
                htmlFor='country_code'
                className='block mb-2 text-sm font-medium text-start w-40'
              >
                C&oacute;digo de Pa&iacute;s
                <input
                  type='tel'
                  id='country_code'
                  name='country_code'
                  className='bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full'
                  placeholder='+54'
                  pattern='^\+?\d{1,3}$'
                  required
                />
              </label>
              <label
                htmlFor='phone'
                className='block mb-2 text-sm font-medium text-start w-full'
              >
                N&uacute;mero de Telefono
                <input
                  type='tel'
                  id='phone'
                  name='phone'
                  className='bg-gray-50 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 w-full'
                  placeholder='1144522678'
                  pattern='\d{4,14}'
                  required
                />
              </label>
            </div>

            <div className='h-fit row-start-6'>
              <div className='inline-flex items-center'>
                <button className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {openPopupOk &&
        <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-emerald-500">
          <span className="text-xl inline-block mr-5 align-middle">
            <i className="fas fa-bell" />
          </span>
          <span className="inline-block align-middle mr-8">
            <b className="capitalize">Gracias!</b> Nos pondremos en contacto a la brevedad.
          </span>
          <button onClick={handleOffPopup} className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none">
            <span>×</span>
          </button>
        </div>
      }
      {openPopupError &&
        <div className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
          <span className="text-xl inline-block mr-5 align-middle">
            <i className="fas fa-bell" />
          </span>
          <span className="inline-block align-middle mr-8">
            <b className="capitalize">Ocurrió un error</b> al procesar su reserva contáctanos por whatsapp.
          </span>
          <button onClick={handleOffPopup} className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none">
            <span>×</span>
          </button>
        </div>
      }
    </div>
  );
};

export default Form;
