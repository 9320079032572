import axios from "axios"

class BookingService{
    sendForm = async(booking) => {
        return axios.post('https://api.jadeunlimited.com/api/public/booking/reserve',booking)
        .then(res => {
            if(res.status == 201){
                return "Reserva exitosa"
            }
        })
        .catch(e => "Error")   
    }
}

export default new BookingService();