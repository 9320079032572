import React from 'react';

export function CounterInput({ min = 0, max, className = '', ...inputProps }) {
	const [value, setValue] = React.useState(min);
	const isDisabledDecrease = value <= min;
	const isDisabledIncrease = !!max && value >= max;

	const handleIncrease = () => {
		if (isDisabledIncrease) return;
		setValue((prevValue) => prevValue + 1);
	};
	const handleDecrease = () => {
		if (isDisabledDecrease) return;
		setValue((prevValue) => prevValue - 1);
	};

	const handleChange = (event) => {
		const newValue = event.target.value;
		setValue(newValue);
	};

	const handleFocusOff = () => {
		setValue((prevValue) => {
			const newValue = Number(prevValue);
			if (isNaN(newValue)) return min;
			if (newValue < min) return min;
			if (!!max && newValue > max) return max;
			return newValue;
		});
	};

	return (
		<div className={'h-10 w-auto flex gap-2 items-center mt-1 ' + className}>
			<button
				onClick={handleDecrease}
				className='rounded-full hover:bg-gray-300 disabled:text-gray-300 disabled:hover:bg-transparent'
				disabled={isDisabledDecrease}
				type='button'
			>
				<svg
					width='20'
					height='20'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M4 12C4 11.4477 4.44772 11 5 11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H5C4.44772 13 4 12.5523 4 12Z'
						fill='currentColor'
					/>
				</svg>
			</button>

			<input
				className='px-2 text-center h-full appearance-none outline-none border text-gray-800 bg-gray-50 w-24 border-gray-200 rounded'
				value={value}
				onChange={handleChange}
				onBlur={handleFocusOff}
				{...inputProps}
			/>

			<button
				onClick={handleIncrease}
				className='rounded-full hover:bg-gray-300 disabled:text-gray-300 disabled:hover:bg-transparent'
				disabled={isDisabledIncrease}
				type='button'
			>
				<svg
					width='20'
					height='20'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13V5C13 4.44772 12.5523 4 12 4Z'
						fill='currentColor'
					/>
				</svg>
			</button>
		</div>
	);
}
