import React, { useState } from 'react';
import {
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	Drawer,
	List,
	ListItem,
	ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const Header = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const navigate = useNavigate();

	const toggleDrawer = (open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setDrawerOpen(open);
	};

	const handleNavigation = (path) => {
		navigate(path);
		const sectionId = path.replace('/', '');
		const section = document.getElementById(sectionId);
		if (section) {
			window.scrollTo({
				top: section.offsetTop,
				behavior: 'smooth',
			});
		}
		setDrawerOpen(false);
	};

	const menuItems = [
		{ text: 'Home', path: '/home' },
		{ text: 'Form', path: '/form' },
		{ text: 'Gallery', path: '/gallery' },
		{ text: 'About Us', path: '/about-us' },
	];

	return (
		<>
			<AppBar
				position='fixed'
				sx={{ bgcolor: 'transparent', color: 'black', boxShadow: 'none' }}
			>
				<Toolbar sx={{ justifyContent: 'space-between' }}>
					<Typography
						variant='h5'
						className='text-6xl font-bold italic text-primary-color'
						component='div'
						sx={{ flexGrow: 1 }}
					>
						Jade Group
					</Typography>
					<IconButton
						edge='end'
						color='inherit'
						aria-label='menu'
						sx={{ display: { xs: 'block', md: 'none' } }}
						onClick={toggleDrawer(true)}
					>
						<MenuIcon />
					</IconButton>
					<div className='hidden md:flex space-x-4'>
						{menuItems.map((item) => (
							<Typography
								key={item.text}
								variant='h6'
								component='div'
								className='text-border'
								onClick={() => handleNavigation(item.path)}
								sx={{ cursor: 'pointer' }}
							>
								{item.text}
							</Typography>
						))}
					</div>
				</Toolbar>
			</AppBar>

			<Drawer
				anchor='right'
				open={drawerOpen}
				onClose={toggleDrawer(false)}
				sx={{ display: { xs: 'block', md: 'none' } }}
			>
				<List sx={{ width: 250 }}>
					{menuItems.map((item) => (
						<ListItem
							button
							key={item.text}
							onClick={() => handleNavigation(item.path)}
						>
							<ListItemText primary={item.text} />
						</ListItem>
					))}
				</List>
			</Drawer>
		</>
	);
};

export default Header;
