import React from 'react';

const AboutUs = () => {
  return (
    <div className="w-full max-w-2xl mx-auto text-center text-4xl text-black py-4 px-6 overflow-auto">
      <h1 className="text-3xl font-bold mb-4">Sobre Nosotros</h1>
      <p className="text-sm mb-4 leading-relaxed">
        En Jade, creemos que la comodidad de nuestros huéspedes es la prioridad absoluta. Nos especializamos en ofrecer experiencias de alojamiento temporario que te permiten centrarte en lo que realmente importa: el motivo de tu viaje. Ya sea que estés aquí por negocios, placer o cualquier otra razón, nuestra misión es asegurarnos de que disfrutes de un espacio cómodo, funcional y acogedor.
      </p>
      <p className="text-sm leading-relaxed">
        Con más de 7 años de experiencia en el sector, hemos aprendido a anticipar y superar las expectativas de nuestros clientes, ofreciendo servicios personalizados que hacen que tu estancia sea lo más placentera posible. En Jade, cada detalle cuenta, y estamos comprometidos a brindarte una experiencia sin preocupaciones, donde lo único que tengas que pensar es en disfrutar tu viaje.
      </p>
    </div>
  );
};

export default AboutUs;
