import React from 'react';
import WhatsAppButton from './WhatsAppButton';

const Section = ({ id, children, bgColor }) => (
	<div
		id={id}
		className={`section h-screen flex items-center justify-center ${bgColor}`}
	>
		{children}
		<WhatsAppButton />
	</div>
);

export default Section;
