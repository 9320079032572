import React from 'react';

const Home = () => {
  return (
    <div className="w-full text-center py-10">
      <h2 className="text-6xl font-bold italic transform rotate-2 text-primary-color drop-shadow-[0_0_10px_rgba(255,255,255,0.8)] glow">
        Jade
      </h2>
      <h4 className="text-3xl italic transform -rotate-2 text-secondary-color drop-shadow-[0_0_10px_rgba(255,255,255,0.6)] glow mt-4">
        Tu hogar en Buenos Aires
      </h4>
    </div>
  );
};

export default Home;
