import React, { useEffect, useState } from 'react';
import BedRoom from '../static/images/bulnes-bedroom.jpg'
import Door from '../static/images/bulnes-door.jpg'
import Kitchen from '../static/images/bulnes-kitchen.jpg'
import Living from '../static/images/bulnes-living.jpg'

const Gallery = () => {
	const images = [BedRoom, Door, Kitchen, Living];
	const [currentIndex, setCurrentIndex] = useState(0);
  
	useEffect(() => {
	  const interval = setInterval(() => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
	  }, 3000); // Cambia cada 3 segundos
  
	  return () => clearInterval(interval); // Limpia el intervalo cuando se desmonta el componente
	}, [images.length]);
  
	return (
	  <div className='w-full h-full flex flex-col items-center justify-center text-black relative'>
		<h2 className='text-3xl font-bold mb-2'>Galería</h2>
		<small className='text-base mb-4'>Contáctanos para más temporarios</small>
		<div className='w-full sm:w-3/4 h-3/4 relative flex items-center justify-center'>
		  <img
			src={images[currentIndex]}
			alt={`Imagen ${currentIndex + 1}`}
			className='max-w-full max-h-full rounded-lg transition-opacity duration-1000 ease-in-out opacity-0'
			style={{ opacity: 1 }}
		  />
		</div>
	  </div>
	);
  };
  
  
  
  
  

export default Gallery;
