import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Section from './Section';
import Home from '../pages/Home';
import Form from '../pages/Form';
import Gallery from '../pages/Gallery';
import AboutUs from '../pages/AboutUs';

const ScrollSections = () => {
  const navigate = useNavigate();
  const sections = useRef([]);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight / 2;
    sections.current.forEach(section => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;
      const sectionId = section.id;

      if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
        navigate(`/${sectionId}`);
      }
    });
  };

  useEffect(() => {
    sections.current = document.querySelectorAll('.section');
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="content mt-16 mb-16">
      <Section id="home" bgColor="bg-buenos-aires bg-cover"><Home /></Section>
      <Section id="form" bgColor="bg-green-200"><Form /></Section>
      <Section id="gallery" bgColor="bg-white"><Gallery /></Section>
      <Section id="about-us" bgColor="bg-white"><AboutUs /></Section>
      
    </div>
  );
};

export default ScrollSections;
